<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <!--营业执照信息-->
      <div class="formDiv">
        <ProductBaseInfo v-show="clickIndex == 1" ref="productBaseInfo"></ProductBaseInfo>
        <RegisteCerticate v-show="clickIndex == 2" ref="registeCerticate"></RegisteCerticate>
        <RecordCertificateP v-show="clickIndex == 3" ref="recordCertificateP"></RecordCertificateP>
        <DealerInfo v-show="clickIndex == 4" ref="dealerInfo"></DealerInfo>
      </div>
      <div class="saveDiv clearfix" v-if="status == 2">
        <div class="pageBtn finger btnSure fr marginLeft20" @click="post(2)">提交</div>
        <div  class="noticeText">(点击提交,将信息提交至管理端等待审核)</div>
        <div class="pageBtn finger btnSure fr marginLeft20" @click="post(1)">保存</div>
        <span class="pageBtn finger btnReset fr" @click="back">返回</span>
      </div>
    </div>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TipsComponent from '@/components/tipsComponent'
import { debounce } from "@/service/utils";
export default {
  name: 'editProduct',
  components: {
    TipsComponent,
    ProductBaseInfo: () => import('./productBaseInfo'),
    RegisteCerticate: () => import('./registeCerticate'),
    RecordCertificateP: () => import('./recordCertificateP'),
    DealerInfo: () => import('./dealerInfo')
  },
  computed: {
    ...mapState(['productInfo'])
  },
  data() {
    return {
      postStatus: false,
      detailInfo: {}, // 有id 查询详情
      id: '',
      navFrist: [
        {
          name: '基础信息',
          type: 1
        }
      ],
      clickIndex: 1,
      status: 2,
      lastPostObj: {}
    }
  },
  created() {
    this.status = this.$route.query.status // 1是只查看 2是有修改
    this.id = this.$route.query.id
    this.queryDetail()
  },
  mounted() {
    this.$root.$on('changeType', res => {
      let obj = {}
      this.navFrist = [
        {
          name: '基础信息',
          type: 1
        }
      ]
      if (res == 1) {
        obj = {
          name: '注册证',
          type: 2
        }
      } else if (res == 2) {
        obj = {
          name: '备案凭证',
          type: 3
        }
      }
      if(obj.name) {
        this.navFrist = [
          ...this.navFrist,
          obj,
          {
            name: '经销信息',
            type: 4
          }
        ]
      }

      if (res == 1) {
        this.clickIndex = 2
      } else {
        this.clickIndex = 3
      }
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    queryDetail() {
      this.$http.get(this.$api.productProductDetail, { review_id: this.id }, true).then(res => {
        this.detailInfo = res.data
        this.$root.$emit('productInfo', this.detailInfo)
        this.$store.commit('setProductInfo', this.detailInfo)
        this.$root.$emit('reviewId', this.id)
        let obj = {}
        if (this.detailInfo.qualification_maintain_type == 1) {
          obj = {
            name: '注册证',
            type: 2
          }
        } else if (this.detailInfo.qualification_maintain_type == 2) {
          obj = {
            name: '备案凭证',
            type: 3
          }
        }
        if(obj.name) {
          this.navFrist = [
            ...this.navFrist,
            obj,
            {
              name: '经销信息',
              type: 4
            }
          ]
        }
        setTimeout(() => {
          this.$root.$emit('productInfo', this.detailInfo)
        }, 500)
      })
    },
    clickNav(type) {
      this.clickIndex = type
      let info = this.detailInfo ? JSON.parse(JSON.stringify(this.detailInfo)) : {}
      this.$store.commit('setProductInfo', info)
      // this.$root.$emit('productInfo', info)
    },
    // 提交
    post(num) {
      let postFrom1 = this.$refs.productBaseInfo.postFrom
      let postFrom2 = {}
      let postFrom4 = {}
      if (!postFrom1.name) {
        this.$Message.warning('请选择/填写产品名称')
        return
      }
      if (!postFrom1.factory_id) {
        this.$Message.warning('请选择厂家')
        return
      }
      if (!postFrom1.storage_condition) {
        this.$Message.warning('请填写储运条件')
        return
      }
      // if (!postFrom1.unit.dose_unit || !postFrom1.unit.qty || !postFrom1.unit.unit) {
      //   this.$Message.warning('请填写最小包装规格')
      //   return
      // }
      // if (!postFrom1.packing_size) {
      //   this.$Message.warning('请填写包装规格')
      //   return
      // }
      postFrom1.unit = {
        qty: postFrom1.unit.qty ? postFrom1.unit.qty : '',
        unit: postFrom1.unit.unit ? postFrom1.unit.unit : '',
        dose_unit: postFrom1.unit.dose_unit ? postFrom1.unit.dose_unit : ''
      }
      postFrom1.packing_size = {
        qty: postFrom1.packing_size.qty ? postFrom1.packing_size.qty : '',
        unit: postFrom1.packing_size.unit ? postFrom1.packing_size.unit : '',
        dose_unit: postFrom1.packing_size.dose_unit ? postFrom1.packing_size.dose_unit : ''
      }
      if (!postFrom1.qualification_maintain_type) {
        this.$Message.warning('请选择资料维护')
        return
      }
      if (postFrom1.image_urls.length === 0 || postFrom1.image_files.length === 0 || !postFrom1.image_file_name.length === 0) {
        this.$Message.warning('请选上传图片资料')
        return
      }
      if (postFrom1.qualification_maintain_type == '1') {
        postFrom2 = this.$refs.registeCerticate.postFrom
        let arr = this.$refs.registeCerticate.listData
        arr.forEach((item,index) =>{
          item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
        })
        if (!postFrom2.licence_file_key || !postFrom2.licence_file_name || !postFrom2.licence_file_url) {
          this.$Message.warning('请上传注册证')
          return
        }
        if (!postFrom2.licence_code) {
          this.$Message.warning('请填写注册证编号')
          return
        }
        if (!postFrom2.production_address) {
          this.$Message.warning('请填写生产地址')
          return
        }
        if (!postFrom2.creator_company_name) {
          this.$Message.warning('请填写注册人名称')
          return
        }
        if (!postFrom2.creator_company_address) {
          this.$Message.warning('请填写注册人住所')
          return
        }
        if (!postFrom2.proxy_name) {
          this.$Message.warning('请填写代理人名称')
          return
        }
        if (!postFrom2.comprise_desc) {
          this.$Message.warning('请填写结构及组成')
          return
        }
        if (!postFrom2.application_scope) {
          this.$Message.warning('请填写适用范围')
          return
        }
        if (!postFrom2.registrant_time) {
          this.$Message.warning('请选择批准日期')
          return
        }
        if (!postFrom2.end_time) {
          this.$Message.warning('请选择有效期至')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请新增至少一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate!=0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          }
        }
        // 包装单位必填
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        postFrom2.licence_product_code = arr
      } else {
        postFrom2 = this.$refs.recordCertificateP.postFrom
        let arr = this.$refs.recordCertificateP.listData
        arr.forEach((item,index) =>{
          item.notax_unit_price =(item.unit_price/(1+item.tax_rate/100)).toFixed(4)
        })
        if (!postFrom2.record_file_name) {
          this.$Message.warning('请上传备案凭证照片')
          return
        }
        if (!postFrom2.record_code) {
          this.$Message.warning('请填写备案号')
          return
        }
        if (!postFrom2.record_name) {
          this.$Message.warning('请填写备案人名称')
          return
        }
        if (!postFrom2.record_time) {
          this.$Message.warning('请选择备案日期')
          return
        }
        if (arr.length == 0) {
          this.$Message.warning('请至少添加一组规格型号')
          return
        }
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].model_name) {
            this.$Message.warning(`请填写第${i + 1}组规格型号再提交`)
            return
          }
          if (!arr[i].unit_price) {
            this.$Message.warning(`请填写第${i + 1}组单价数据再提交`)
            return
          }
          if (!arr[i].tax_rate && arr[i].tax_rate != 0) {
            this.$Message.warning(`请填写第${i + 1}组税率数据再提交`)
            return
          }
          if (arr[i].tax_rate * 1 > 100) {
            this.$Message.warning(`第${i + 1}税率的可输入值只能在0-100`)
            return
          }
        //   if (!arr[i].unit.dose_unit || !arr[i].unit.qty || !arr[i].unit.unit) {
        //     this.$Message.warning('请填写最小包装规格')
        //   return
        // }
        }
        // 包装单位必填
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].unit.unit) {
            this.$Message.warning(`请填写第${i + 1}组最小包装规格再提交`)
            return
          }
        }
        postFrom2.record_product_code = arr
      }
      postFrom4 = this.$refs.dealerInfo.postFrom
      if (!postFrom4.payment_days) {
        this.$Message.warning('请填写账期')
        return
      }
      if (!postFrom4.deal_type || postFrom4.deal_type == 'null') {
        this.$Message.warning('请填写交易类型')
        return
      }
      let lastPostFrom = Object.assign({}, postFrom1, postFrom2, postFrom4)
      lastPostFrom.image_files = lastPostFrom.image_files ? lastPostFrom.image_files.join(',') : ''
      lastPostFrom.image_file_name = lastPostFrom.image_file_name ? lastPostFrom.image_file_name.join(',') : ''
      lastPostFrom.review_id = this.detailInfo.review_id
      lastPostFrom.save_type = num
      // 分为了两块，一个licence_product_code一个record_product_code
      if (lastPostFrom.licence_product_code != null) {
        if (lastPostFrom.licence_product_code[0] != null) {
          for (let m = 0; m < lastPostFrom.licence_product_code.length; m++) {
            if (!lastPostFrom.licence_product_code[m].packing_size.dose_unit) {
              lastPostFrom.licence_product_code[m].packing_size.dose_unit = ''
            }
            if (!lastPostFrom.licence_product_code[m].packing_size.qty) {
              lastPostFrom.licence_product_code[m].packing_size.qty = ''
            }
            if (!lastPostFrom.licence_product_code[m].packing_size.unit) {
              lastPostFrom.licence_product_code[m].packing_size.unit = ''
            }
            if (!lastPostFrom.licence_product_code[m].unit.unit) {
              lastPostFrom.licence_product_code[m].unit.unit = ''
            }
            if (!lastPostFrom.licence_product_code[m].unit.dose_unit) {
              lastPostFrom.licence_product_code[m].unit.dose_unit = ''
            }
            if (!lastPostFrom.licence_product_code[m].unit.qty) {
              lastPostFrom.licence_product_code[m].unit.qty = ''
            }
          }
        }
      }
      if (lastPostFrom.record_product_code != null) {
        if (lastPostFrom.record_product_code[0] != null) {
          for (let i = 0; i < lastPostFrom.record_product_code.length; i++) {
            if (!lastPostFrom.record_product_code[i].packing_size.dose_unit) {
              lastPostFrom.record_product_code[i].packing_size.dose_unit = ''
            }
            if (!lastPostFrom.record_product_code[i].packing_size.qty) {
              lastPostFrom.record_product_code[i].packing_size.qty = ''
            }
            if (!lastPostFrom.record_product_code[i].packing_size.unit) {
              lastPostFrom.record_product_code[i].packing_size.unit = ''
            }
            if (!lastPostFrom.record_product_code[i].unit.unit) {
              lastPostFrom.record_product_code[i].unit.unit = ''
            }
            if (!lastPostFrom.record_product_code[i].unit.dose_unit) {
              lastPostFrom.record_product_code[i].unit.dose_unit = ''
            }
            if (!lastPostFrom.record_product_code[i].unit.qty) {
              lastPostFrom.record_product_code[i].unit.qty = ''
            }
          }
        }
      }
      if (num == 1) {
        this.$http.post(this.$api.productSave, lastPostFrom, true).then(res => {
          this.postStatus = false
          this.$Message.success('保存成功')
          sessionStorage.setItem('updateList','0')
          // this.$router.go(-1)
        })
        return
      }
      this.lastPostObj = lastPostFrom
      this.postStatus = true
    },
    surePost:debounce(function() {
      this.$http.post(this.$api.productSave, this.lastPostObj, true).then(res => {
        this.postStatus = false
        this.$Message.success('提交审核成功')
        this.$router.go(-1)
      })
      sessionStorage.setItem('updateList','0')
    }, 1000, true)
  }
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
      position: relative;
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      position: relative;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.noticeText{
  position: absolute;
  bottom: -20px;
  color: red;
  right: -20px;
  font-size: 14px;
}
</style>
